
/* 
   Basic spinner animation 
   taken from: https://www.w3schools.com/howto/howto_css_loader.asp 
*/

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #6ea2b0;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
